import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  Product,
  ValueContainer,
  ValueContainerGroup,
} from '../../../pim-graphql.model';
import { PimGraphqlService } from '../../../pim-graphql.service';
import {
  Channel,
  SAPAttribute,
  SAPAttributeGroup,
  SAPAttributeGroups,
} from './detail-attributes.model';

@Injectable({
  providedIn: 'root',
})
export class DetailAttributesService {
  constructor(private pim: PimGraphqlService) {}

  getDetailAttributes(id: string): Observable<SAPAttributeGroups> {
    return this.pim.getProductAttributesById(id).pipe(
      map((product) => {
        return this.getSAPAttributeGroups(product);
      }),
    );
  }

  private getSAPAttributeGroups(product: Product): SAPAttributeGroups {
    const sapAttributes = product.valuesByDataTypeGroup?.find(
      (vcg) => vcg.dataTypeGroup.id === 'ATG_SAP_Attribute',
    );

    //Shallow copy to not alter Apollo cache
    const sapAttrsCopy = [...(sapAttributes?.values || [])];

    //Map ATG_SAP_Attribute to ATG_prioSapAttributes
    const resultGroups = product.valuesByDataTypeGroup?.reduce<
      SAPAttributeGroup[]
    >((groups, vcg) => {
      if (vcg.dataTypeGroup.id.startsWith('ATG_prioSapAttributes')) {
        const channel = this.getGroupChannels(vcg);

        const groupAttributes = this.getGroupAttributes(
          vcg,
          channel.length,
          sapAttributes?.values,
          sapAttrsCopy,
        );

        if (groupAttributes?.length > 0) {
          groups.push({
            groupTitle: {
              title: vcg.values[0].values[0].value,
              translatable: false,
            },
            channels: channel,
            attributes: groupAttributes,
          });
        }
      }
      return groups;
    }, []);

    //Filter empty attributes
    //sapAttrsCopy = sapAttrsCopy?.filter((val) => val.values[0].value != null);
    if (sapAttrsCopy && sapAttrsCopy.length > 0) {
      resultGroups?.push(this.getUngroupedSAPAttributes(sapAttrsCopy));
    }

    return {
      title: sapAttributes?.dataTypeGroup.title,
      groups: resultGroups ? resultGroups : [],
    };
  }

  private getGroupChannels(vcg: ValueContainerGroup): Channel[] {
    const vc = vcg.values.find((container: ValueContainer) => {
      return container.attribute.id.startsWith('AT_OutputChannel');
    });

    if (!vc) return [];

    return vc.values.map((val) => {
      return {
        id: val.valueId,
        title: val.value,
      };
    });
  }

  private getGroupAttributes(
    vcg: ValueContainerGroup,
    nrOfChannel: number,
    sapAttrs?: ValueContainer[],
    sapAttrsCopy?: ValueContainer[],
  ): SAPAttribute[] {
    const vc = vcg.values.find((container: ValueContainer) => {
      return container.attribute.id.startsWith('AT_prioSap');
    });

    if (!vc) return [];

    return vc.values.reduce<SAPAttribute[]>((sapAttributes, value) => {
      const attrs = nrOfChannel > 0 ? sapAttrs : sapAttrsCopy;
      const valueIndex = attrs?.findIndex(
        (val) => val.attribute.id === value.valueId,
      );

      if (valueIndex !== undefined && valueIndex > -1) {
        let valueContainer;
        if (nrOfChannel > 0 && attrs) {
          valueContainer = attrs[valueIndex];
        } else {
          //Remove and map attribute from sapAttrsCopy
          valueContainer = attrs?.splice(valueIndex, 1)[0];
        }
        if (valueContainer) {
          sapAttributes.push({
            attributeId: valueContainer.attribute.id,
            attribute: valueContainer.attribute.title,
            value: valueContainer.values.map((val) => val.value).join('\n'),
            unit: valueContainer.values[0]?.unit?.title,
            validationType: valueContainer.attribute.validation?.name,
          });
        }
      }
      return sapAttributes;
    }, []);
  }

  private getUngroupedSAPAttributes(
    sapAttrsCopy: ValueContainer[],
  ): SAPAttributeGroup {
    const mappedSAPAttributes = sapAttrsCopy.map((attr) => {
      return {
        attributeId: attr.attribute.id,
        attribute: attr.attribute.title,
        value: attr.values.map((val) => val.value).join('\n'),
        unit: attr.values[0]?.unit?.title,
        validationType: attr.attribute.validation?.name,
      };
    });

    return {
      groupTitle: {
        title: 'detail.product_attributes.ungrouped_header',
        translatable: true,
      },
      channels: [],
      attributes: mappedSAPAttributes,
    };
  }
}
